<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <!-- <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="处理结果">
                <a-select placeholder="请选择处理结果" v-model="queryParam.processStatus" style="width: 100%" allow-clear>
                  <a-select-option value="1">
                    待处理
                  </a-select-option>
                  <a-select-option value="2">
                    已处理
                  </a-select-option>
                  <a-select-option value="3">
                    暂不处理
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="advanced && { float: 'right', overflow: 'hidden' } || {}">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div> -->
      <!-- 增加修改 -->
      <create-form ref="createForm" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered">
        <!---图片显示--->
        <span slot="pic" slot-scope="text, record">
          <!-- <img style="width:50px;height:50px" :src="item" v-for="(item, key) in record.imgUrls ? record.imgUrls.split(',') : []" :key="key" /> -->
          <viewer :images="record.imgUrls ? record.imgUrls.split(',') : []">
            <img v-for="(item, key) in record.imgUrls ? record.imgUrls.split(',') : []" style="width:50px;height:50px;object-fit: cover;margin-right:5px" :src="item" :key="key">
          </viewer>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <div v-if="record.processStatus == 1">
            <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['app:feedback:update']">
              <a-icon type="edit" />处理
            </a>
          </div>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize" />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { feedbackPage } from '@/api/pages/feedback'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Feedback',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        content: undefined,
        processStatus: undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '反馈类型',
          dataIndex: 'typeStr',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '反馈内容',
          dataIndex: 'content',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '反馈图片',
          dataIndex: 'imgUrls',
          scopedSlots: { customRender: 'pic' },
          width: 110,
          ellipsis: true,
          align: 'center'
        },
        {
          title: '处理状态',
          dataIndex: 'processStatusStr',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '处理结果',
          dataIndex: 'processResult',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '联系方式',
        //   dataIndex: 'contact',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '反馈时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created() {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询意见反馈列表 */
    getList() {
      this.loading = true
      feedbackPage(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.list.forEach(element => {
          if (element.type === 1) {
            element.typeStr = '意见建议'
          } else if (element.type === 2) {
            element.typeStr = '故障报告'
          } else if (element.type === 3) {
            element.typeStr = '投诉'
          }
          // :1-待处理，2-已处理，3-暂不处理
          if (element.processStatus === 1) {
            element.processStatusStr = '待处理'
          } else if (element.processStatus === 2) {
            element.processStatusStr = '已处理'
          } else if (element.processStatus === 3) {
            element.processStatusStr = '暂不处理'
          }
        })
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        content: undefined,
        processStatus: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    }
  }
}
</script>
